import '../Count/Count.css';
import CountUp from 'react-countup';
import { Coin, DoubleCoin } from '../../assets/icons/icons';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion'

function Count({ countVisible, total, setCountVisible, user, setTotal }) {

    const location = useLocation()

    useEffect(() => {
        if (location.pathname === '/' || location.pathname === '/business') {
            setCountVisible(true)
        } else {
            setCountVisible(false)
        }
    }, [location])

    const balanceRecharge = (3600000 / Number(total.persec))

    useEffect(() => {
        if (!user) return
        if (total.persec > 0) {
            console.log(balanceRecharge)
            const rechargeBalanceTimer = setInterval(() => {
                setTotal((prevValue) => ({ ...prevValue, initial: prevValue.initial + 1 }))
            }, balanceRecharge)
            return () => { clearInterval(rechargeBalanceTimer) }
        }
    }, [total.persec])

    return (
        <motion.div
            className={`count-coin ${countVisible ? 'count-coin_scroll' : ''} ${location.pathname === '/business' ? 'count-coin_business' : 'count-coin_business_unvisible'}`}>
            <p className={`main__coin ${countVisible ? 'main__coin_visible' : 'main__coin_unvisible'}`}>
                {/* <Coin mainClassName={'main__coin-icon'} /> */}
                <DoubleCoin mainClassName={'main__coin-icon'} />
                <CountUp
                    end={Math.floor(total?.initial + total?.click)}
                    duration={0.5}
                    separator=" "
                    preserveValue={true}
                />
            </p>
        </motion.div>
    )
}

export default Count