import React, { useEffect, useRef, useState } from 'react';
import { THEME_DARK_TYPE } from '../../../assets/utils/constants';

const VerticalEqualizerBar = ({ clickable, doubleClickable }) => {
    const canvasRef = useRef(null);

    function randomIntFromInterval(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min);
    }
    const [numBlocksActive, setNumBlocksActive] = useState(78);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        canvas.width = 13; // Ширина полоски эквалайзера
        canvas.height = 296; // Высота экрана

        const numBlocks = 76; // Количество горизонтальных блоков
        // const numBlocksActive = randomIntFromInterval(1, 55); // Количество горизонтальных блоков
        const blockHeight = 2; // Высота каждого блока
        const blockSpacing = 2; // Расстояние между блоками
        let frame = 0;

        // Логика изменения количества блоков
        const updateNumBlocks = () => {
            if (clickable && !doubleClickable) {
                setNumBlocksActive(randomIntFromInterval(10, 35));
            }
            else if (clickable && doubleClickable) {
                setNumBlocksActive(randomIntFromInterval(35, 76));
            }
        };

        const animate = () => {
            requestAnimationFrame(animate);
            frame += 0.1; // Скорость анимации
            // Очистить холст
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            // Анимация движения блоков вверх-вниз
            const maxActiveBlocks = !clickable ? 0 : Math.floor((Math.sin(frame) + 1) / 2 * numBlocksActive); // Количество активных блоков

            for (let i = 0; i < numBlocks; i++) {
                const y = canvas.height - (i + 1) * (blockHeight + blockSpacing); // Позиция каждого блока
                if (i < maxActiveBlocks) {
                    // Цвет активных блоков
                    ctx.fillStyle = `#63C861`;
                } else {
                    // Цвет неактивных блоков
                    if (window.Telegram.WebApp.colorScheme === THEME_DARK_TYPE) {
                        ctx.fillStyle = `#45395C`;
                    }
                    else {
                        ctx.fillStyle = `#C9C9DC`;
                    }
                }
                // Нарисовать каждый блок
                ctx.fillRect(0, y, canvas.width, blockHeight);
            }
        };
        animate();
        const interval = setInterval(updateNumBlocks, 1000); // Обновляем количество блоков каждую секунду

        return () => clearInterval(interval); // Очищаем таймер при размонтировании компонента

    }, [clickable, doubleClickable, numBlocksActive]);
    return <canvas ref={canvasRef} />;
};

export default VerticalEqualizerBar;

