import '../PopupTask/PopupTask.css';
import TemplatePopup from '../../TemplatePopup/TemplatePopup';
import { Coin, DoubleCoin } from '../../../assets/icons/icons';
import { motion } from 'framer-motion';
import mainApi from '../../../assets/api/MainApi';
import noPhoto from '../../../assets/images/NoPhoto.webp'
import { useState } from 'react';

function PopupTask({ isOpen, setOpen, data, currentId, setTotal, total, rewards, setRewards }) {
    const curTask = data.find(item => item.id === currentId)
    const [alreadyBuy, setAlreadyBuy] = useState(false)

    function handlePay() {
        setRewards((prevState) => prevState.map(item => {
            if (item.id !== currentId) return item
            else { return { ...item, left_count: item.left_count - 1 } }
        }))

        mainApi
            .buyAward({ id: currentId })
            .then((res) => {
                console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
                setAlreadyBuy(true)
            })
            .finally(() => {
                // setPreloaders(false)
            })

        setOpen(!isOpen)
        setTotal((prevState) => ({
            ...prevState,
            initial: Number(prevState.initial) - Number(curTask?.price)
        }))
        window.Telegram?.WebApp.HapticFeedback.impactOccurred('soft')
    }

    return (
        <>
            {isOpen ?
                <motion.div
                    onClick={() => setOpen(!isOpen)}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className='popup__bg' />
                : null}
            <TemplatePopup isOpen={isOpen} setOpen={setOpen}>
                {curTask?.image ?
                    <img src={curTask?.image} alt='' className='popup-task__img'></img>
                    :
                    <img
                        alt=''
                        className='popup-task__img'
                        src={noPhoto}>
                    </img>
                }
                <p className='popup-task__text'>{curTask?.title}</p>
                <p className='popup-task__desc'>{curTask?.description}</p>
                <p className='popup-task__price'>
                    <DoubleCoin />
                    {new Intl.NumberFormat('ru-Ru').format(
                        Number(curTask?.price),
                    )}
                </p>
                {total.initial + total.persec + total.click > Number(curTask?.price) ?
                    <button onClick={() => handlePay()} className={`popup-level-up__btn ${alreadyBuy ? 'popup-level-up__btn_already-buy' : ''}`}>
                        {!alreadyBuy ?
                            'Получить'
                            :
                            'Упс, награда закончилась'
                        }
                    </button>
                    :
                    <button className='popup-task__btn_error'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M10 20C8.63182 20 7.3443 19.7386 6.13746 19.2159C4.93062 18.6996 3.86576 17.98 2.94288 17.0571C2.02646 16.1342 1.30687 15.0694 0.784124 13.8625C0.261375 12.6557 0 11.3682 0 10C0 8.63182 0.261375 7.3443 0.784124 6.13746C1.30687 4.93062 2.02646 3.86899 2.94288 2.95257C3.86576 2.02969 4.9274 1.30687 6.12778 0.784124C7.33462 0.261375 8.62214 0 9.99032 0C11.365 0 12.6557 0.261375 13.8625 0.784124C15.0694 1.30687 16.1342 2.02969 17.0571 2.95257C17.98 3.86899 18.7028 4.93062 19.2256 6.13746C19.7483 7.3443 20.0097 8.63182 20.0097 10C20.0097 11.3682 19.7483 12.6557 19.2256 13.8625C18.7028 15.0694 17.98 16.1342 17.0571 17.0571C16.1342 17.98 15.0694 18.6996 13.8625 19.2159C12.6557 19.7386 11.3682 20 10 20ZM10.0097 11.6747C10.5841 11.6747 10.8777 11.3746 10.8906 10.7744L11.0455 5.91481C11.0584 5.61794 10.9648 5.37593 10.7648 5.18877C10.5647 4.99516 10.3098 4.89835 10 4.89835C9.68377 4.89835 9.42885 4.99516 9.23524 5.18877C9.04163 5.37593 8.95127 5.61471 8.96418 5.90513L9.09971 10.7841C9.11907 11.3779 9.42239 11.6747 10.0097 11.6747ZM10.0097 15.0048C10.3324 15.0048 10.6099 14.9016 10.8422 14.6951C11.0745 14.4885 11.1907 14.2239 11.1907 13.9013C11.1907 13.585 11.0745 13.3204 10.8422 13.1075C10.6099 12.8945 10.3324 12.788 10.0097 12.788C9.68054 12.788 9.39981 12.8945 9.16747 13.1075C8.93514 13.3204 8.81897 13.585 8.81897 13.9013C8.81897 14.2239 8.93514 14.4885 9.16747 14.6951C9.40626 14.9016 9.687 15.0048 10.0097 15.0048Z" fill="#FF614C" />
                        </svg>
                        У вас недостаточно средств
                    </button>}
            </TemplatePopup>
        </>
    );
}

export default PopupTask;