import { useEffect, useState } from 'react';
import { Coin } from '../../assets/icons/icons';
import defaultPhoto from '../../assets/images/defaultPhoto.webp';
import '../Profile/Profile.css';
import { motion } from "framer-motion"
import { CARDS_LIMIT, NAV_PROFILE, TASK_COUPON } from '../../assets/utils/constants';
import ProfileCard from './ProfileCard/ProfileCard';
import ProfilePopup from './ProfilePopup/ProfilePopup';
import ProfileInfo from './ProfileInfo/ProfileInfo';
import mainApi from '../../assets/api/MainApi';
import { useLocation } from 'react-router-dom';
import MiniPreloader from '../MiniPreloader/MiniPreloader';

function Profile({ setUser, total, personalData, setPersonalData, tabOpen, setTabOpen, user, setTotal, card, personalBonus, setPersonalBonus }) {
    const tg = window.Telegram?.WebApp
    const location = useLocation()
    const [currentCategory, setCurrentCategory] = useState(1)
    const [current, setCurrent] = useState(1)
    const [isOpen, setOpen] = useState(false)
    const [rewards, setRewards] = useState([])
    const [archiv, setArchiv] = useState([])
    const [step, setSteps] = useState(0)
    const [image, setImage] = useState(user?.user_data?.avatar?.urls?.orig ? user?.user_data?.avatar?.urls?.orig : null);

    const [preloaders, setPreloaders] = useState({
        rewards: true,
        archiv: true,
        photo: false
    })

    function handleFilesChange(file) {
        setPreloaders((prevState) => ({
            ...prevState,
            photo: true
        }))
        if (file) {
            let data = new FormData();
            data.append("image", file);
            mainApi
                .uploadImage({ data, type: "avatar" })
                .then((res) => {
                    console.log(res)
                    mainApi.changePhoto({ link: [res._id] })
                        .then((res2) => {
                            setUser((prevValue) => ({
                                ...prevValue,
                                user_data: {
                                    ...prevValue.user_data,
                                    avatar: res2.user_data.avatar
                                }
                            }))
                        })
                }
                )
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setPreloaders((prevState) => ({
                        ...prevState,
                        photo: false
                    }))
                })
        }
        else {
            mainApi.changePhoto({ link: null })
                .then((res2) => {
                    setUser((prevValue) => ({
                        ...prevValue,
                        user_data: {
                            ...prevValue.user_data,
                            avatar: res2.user_data.avatar
                        }
                    }))
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setPreloaders((prevState) => ({
                        ...prevState,
                        photo: false
                    }))
                })
        }
    }


    function handleChange(e) {
        if (e.target.files && e.target.files[0]) {
            // setPreloaderVisible(true)
            setTimeout(() => {
                setImage(URL.createObjectURL(e.target.files[0]))
                // setPreloaderVisible(false);
            }, 1500);
        }
        const input = e.target;
        const file = input.files[0];
        handleFilesChange(file);
    }

    function removePhoto(e) {
        setImage(null)
        handleFilesChange(null);
    }

    useEffect(() => {
        if (!user) return
        setPreloaders((prevState) => ({
            ...prevState,
            rewards: true
        }))
        mainApi
            .getOwnAwards({ limit: CARDS_LIMIT })
            .then((res) => {
                setRewards(res.data)
                // setRewards(TASK_COUPON)
                console.log(res.data);
                setPreloaders((prevState) => ({
                    ...prevState,
                    archiv: true
                }))
                mainApi
                    .getOwnArchivAwards({ limit: CARDS_LIMIT })
                    .then((res) => {
                        setArchiv(res.data)
                        // setArchiv(TASK_COUPON)
                        console.log(res.data);
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => {
                        setPreloaders((prevState) => ({
                            ...prevState,
                            archiv: false
                        }))
                    })
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setPreloaders((prevState) => ({
                    ...prevState,
                    rewards: false
                }))
            })

        // setRewards(TASK_COUPON)
        // setArchiv(TASK_COUPON)
        if (personalData) {
            setSteps(2)
        }
    }, [user])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            mainApi
                .getOwnAwards({ limit: CARDS_LIMIT })
                .then((res) => {
                    setRewards(res.data)
                    console.log(res.data);
                    mainApi
                        .getOwnArchivAwards({ limit: CARDS_LIMIT })
                        .then((res) => {
                            setArchiv(res.data)
                            console.log(res.data);
                        })
                        .catch((err) => {
                            console.log(err);
                        })
                        .finally(() => {
                            // setPreloaders(false)
                        })
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    // setPreloaders(false)
                })
        }, 3000000)

        return () => {
            clearTimeout(delayDebounceFn);
        }
    }, [archiv, rewards])

    useEffect(() => {
        if (tabOpen) {
            setCurrentCategory(3)
            setTabOpen(false)
        }
        else {
            setCurrentCategory(1)
        }
    }, [])

    // const APPLE_LINK = 'https://2020-loyalty-program-backend.cabatest.ru/get-file/public/apple_passes/668bcde62a2d94f3b714269a.pkpass';

    function handleCategoryClick(id) {
        tg?.HapticFeedback.impactOccurred('soft')
        setCurrentCategory(id)
    }

    function getMoreCards() {
        if (currentCategory === 1) {
            const last_id = rewards[rewards.length - 1]?.id
            mainApi
                .getOwnAwards({
                    limit: CARDS_LIMIT,
                    last_id: last_id,
                })
                .then((res) => {
                    console.log(res)
                    if (res.length > 0) {
                        setRewards((prevValue) => (prevValue.concat(res)));
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }
        else if (currentCategory === 2) {
            const last_id = archiv[archiv.length - 1]?.id
            mainApi
                .getOwnArchivAwards({
                    limit: CARDS_LIMIT,
                    last_id: last_id,
                })
                .then((res) => {
                    console.log(res)
                    if (res.length > 0) {
                        setArchiv((prevValue) => (prevValue.concat(res)))
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }

    const handleScroll = (event) => {
        if (window.innerHeight + window.pageYOffset >= document.documentElement.scrollHeight && location.pathname === '/profile' && currentCategory === 1 || currentCategory === 2) {
            getMoreCards()
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);

    useEffect(() => {
        setSteps(2)
    }, [currentCategory])

    const variants = {
        open: { opacity: 1 },
        closed: { opacity: 0 },
        transition: { duration: 1.0 }
    }

    return (
        <>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                // onScroll={handleScrollElement}
                className={`profile`}>
                <div className='profile__header'>
                    <p className='profile__level'>Level <span className='profile__level-number'>{user?.balance_data?.level}</span></p>
                    <div className='profile__img-box'>




                        {image ?
                            <div className="profile__btn_upload">
                                <motion.svg
                                    style={{ zIndex: 13 }}
                                    variants={variants}
                                    animate={image ? 'open' : 'closed'}
                                    onClick={removePhoto} className='profile__btn__delete' xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <rect x="1" y="1" width="30" height="30" rx="15" fill="#5B536B" />
                                    <rect x="1" y="1" width="30" height="30" rx="15" stroke="var(--bg-color)" stroke-width="2" />
                                    <path d="M20 12.0001L12.0001 20.0001" stroke="white" stroke-width="1.6" stroke-linecap="round" />
                                    <path d="M20 20L12.0001 12.0001" stroke="white" stroke-width="1.6" stroke-linecap="round" />
                                </motion.svg>
                            </div>

                            :
                            <>
                                <input
                                    className={`profile__btn_upload profile__wrapper-button ${image ? 'profile__wrapper-button_deletable' : ''}`}
                                    name="image"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleChange}
                                />

                                <label
                                    id="file-upload"
                                    htmlFor="file-upload"
                                    className="profile__btn_upload"
                                // onClick={() => onUploadChange()}
                                >
                                    {preloaders.photo ?
                                        <MiniPreloader isLinkColor={true} isBig={true} />
                                        :
                                        null
                                    }

                                    <motion.svg
                                        style={{ zIndex: 13 }}
                                        variants={variants}
                                        animate={!image ? 'open' : 'closed'}
                                        className='profile__btn__add-krest' xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                        <rect x="1" y="1" width="30" height="30" rx="15" fill="#5B536B" />
                                        <rect x="1" y="1" width="30" height="30" rx="15" stroke="var(--bg-color)" stroke-width="2" />
                                        <path d="M16 10L16 22" stroke="#50FA70" stroke-width="2.4" stroke-linecap="round" />
                                        <path d="M22 15.9999L10 15.9999" stroke="#50FA70" stroke-width="2.4" stroke-linecap="round" />
                                    </motion.svg>
                                </label>
                            </>
                        }



                        <motion.img
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.5 }}
                            src={user?.user_data?.avatar?.urls?.orig ?
                                user?.user_data?.avatar?.urls?.orig :
                                image ?
                                    image :
                                    defaultPhoto}
                            alt=''
                            className='profile__img' />
                        <p className='profile__name'>{tg?.initDataUnsafe?.user?.username ? tg?.initDataUnsafe?.user?.username : user?.user_data?.username}</p>

                    </div>
                    <p className='profile__coin-box'>
                        <Coin mainClassName={'profile__coin'} />
                        {Intl.NumberFormat('en-US', {
                            notation: "compact",
                            maximumFractionDigits: 2
                        }).format(total?.initial + total?.click)}
                    </p>
                </div>
                <div className='profile__nav-block'>
                    {NAV_PROFILE.map((item, i) => {
                        return (
                            <p key={i} className={`profile__nav ${item.id === currentCategory ? 'profile__nav_active' : ''}`} onClick={() => handleCategoryClick(item.id)}>{item.nav_title}</p>
                        )
                    })}
                </div>

                {currentCategory === 1 ?
                    <>
                        <p className='profile__title'>Мои награды</p>
                        {preloaders.rewards ?
                            // <MiniPreloader isBig={true} />
                            <motion.div
                                className='profile__grid'
                            >
                                {Array(4).fill('').map((item, i) => {
                                    return (
                                        <ProfileCard
                                            key={i}
                                            preloade={true}
                                            item={item}
                                            setCurrent={setCurrent}
                                            current={current}
                                            setOpen={setOpen}
                                            isOpen={isOpen}
                                            arhiv={false}
                                        />
                                    )
                                })}
                            </motion.div>
                            :
                            <>
                                {rewards.length > 0 ?
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 0.5 }}
                                        className='profile__grid'
                                    >
                                        {rewards?.map((item, i) => {
                                            return (
                                                <div key={i}>
                                                    <ProfileCard
                                                        item={item}
                                                        setCurrent={setCurrent}
                                                        current={current}
                                                        setOpen={setOpen}
                                                        isOpen={isOpen}
                                                        arhiv={false}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </motion.div>
                                    :
                                    <motion.p
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        className='profile__title_empty'>
                                        Список наград пуст
                                    </motion.p>
                                }
                            </>}
                    </>
                    : currentCategory === 2 ?
                        <>
                            <p className='profile__title'>Архив наград</p>
                            {preloaders.archiv ?
                                // <MiniPreloader isBig={true} />
                                <motion.div
                                    className='profile__grid'
                                >
                                    {Array(4).fill('').map((item, i) => {
                                        return (
                                            <ProfileCard
                                                key={i}
                                                preloade={true}
                                                item={item}
                                                setCurrent={setCurrent}
                                                current={current}
                                                setOpen={setOpen}
                                                isOpen={isOpen}
                                                arhiv={false}
                                            />
                                        )
                                    })}
                                </motion.div>
                                :
                                <>
                                    {archiv.length > 0 ?
                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            transition={{ duration: 0.5 }}
                                            className='profile__grid'
                                        >
                                            {archiv?.map((item, i) => {
                                                return (
                                                    <div key={i}>
                                                        <ProfileCard
                                                            item={item}
                                                            setOpen={setOpen}
                                                            isOpen={isOpen}
                                                            arhiv={true}
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </motion.div>
                                        : <motion.p
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            className='profile__title_empty'>
                                            Архив пуст
                                        </motion.p>
                                    }
                                </>}
                        </>
                        :
                        <>
                            <motion.p
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5 }}
                                className='profile__title'
                            >
                                Личные данные
                            </motion.p>
                            <ProfileInfo
                                setPersonalData={setPersonalData}
                                step={step}
                                setSteps={setSteps}
                                personalData={personalData}
                                setArchiv={setArchiv}
                                setRewards={setRewards}
                                setTotal={setTotal}
                                user={user}
                                total={total}
                                personalBonus={personalBonus}
                                setPersonalBonus={setPersonalBonus}
                            />
                        </>
                }
                <div className='profile__blur'></div>
            </motion.div >
            <ProfilePopup
                isOpen={isOpen}
                setOpen={setOpen}
                data={rewards}
                current={current}
            />
        </>
    );
}

export default Profile;