import '../App/App.css';
import { useEffect, useState } from 'react';
import Main from '../Main/Main';
import { Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Header from '../Header/Header';
import NavBlock from '../NavBlock/NavBlock';
import Business from '../Business/Business';
import Loading from '../Loading/Loading';
import Friends from '../Friends/Friends';
import Rating from '../Raiting/Raiting';
import IncomePopup from '../IncomePopup/IncomePopup';
import LearnPopup from '../LearnPopup/LearnPopup';
import Profile from '../Profile/Profile';
import PhonePopup from '../PhonePopup/PhonePopup';
import GiftPopup from '../GiftPopup/GiftPopup';
import Rewards from '../Rewards/Rewards';
import LevelUpPopup from '../LevelUpPopup/LevelUpPopup';
import mainApi from '../../assets/api/MainApi';
import Count from '../Count/Count';
import { AWARDS_LIMIT, TASK, THEME_LIGHT_TYPE, maxNumberForNoUsernameHash, minNumberForNoUsernameHash, niknames } from '../../assets/utils/constants';
import ForComputer from '../ForComputer/ForComputer';
import { ThemeContext } from "../../assets/contexts/themeContext";
import { useTheme } from "../../assets/hook/use-theme";

function App() {
  const tg = window?.Telegram?.WebApp
  const location = useLocation()
  const navigate = useNavigate();
  const { theme, setTheme } = useTheme();

  const [preloaders, setPreloaders] = useState({
    app: true,
    rewards: true
  })
  const [success, setSucces] = useState(true)
  const [user, setUser] = useState(null)

  const [energyCounter, setEnergyCounter] = useState(0)

  const [income, setIncome] = useState(0)
  const [learn, setLearn] = useState()
  const [personalData, setPersonalData] = useState(false)

  const [isOpenRating, setOpenRating] = useState(false);
  const [isOpenRewards, setOpenRewards] = useState(false);
  const [isOpenLevelUp, setOpenLevelUp] = useState(false);
  const [isGiftOpen, setGiftOpen] = useState(false);

  const [tabOpen, setTabOpen] = useState(false)
  const [phoneOpen, setPhoneOpen] = useState(false)
  const [learnPopup, setLearnPopup] = useState(false)
  const [raitingNumber, setRaitingNumber] = useState(0)
  const [countVisible, setCountVisible] = useState(true)

  const [rewards, setRewards] = useState([])
  const [personalBonus, setPersonalBonus] = useState(false)
  const [total, setTotal] = useState({
    initial: 0,
    persec: 0,
    click: 0
  })

  const [searchParams, setSearchParams] = useSearchParams();
  const tgParams = searchParams.get("tgWebAppStartParam");
  const randomIndex = Math.floor(Math.random() * (niknames.length - 1));
  const randomNumber = Math.floor(Math.random() * (maxNumberForNoUsernameHash - minNumberForNoUsernameHash + 1) + minNumberForNoUsernameHash);

  useEffect(() => {
    if (tg) {
      tg?.expand();
      tg?.ready(); // MAY BE COLLBACK TO CHECK IF READY?
      if (tg.colorScheme === THEME_LIGHT_TYPE) {
        tg.setHeaderColor('#F4F4FD')
        tg.setBackgroundColor('#F4F4FD')
      } else {
        tg.setHeaderColor('#1D1234')
        tg.setBackgroundColor('#1D1234')
      }
      navigate('/')
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setPreloaders((prevState) => ({
        ...prevState,
        app: !preloaders.app
      }))
    }, 6000);
  }, [success])

  useEffect(() => {
    document.body.style.overflow = "initial"
    mainApi
      .getMe()
      .then((res) => {
        console.log(res);
        if (res.short_id !== tg?.initDataUnsafe?.user?.id) {
          mainApi
            .authLogin({
              // initData: `query_id=AAEyeS0XAAAAADJ5LRff1Qpb&user=%7B%22id%22%3A388856114%2C%22first_name%22%3A%22%D0%9B%D1%91%D1%88%D0%B0%22%2C%22last_name%22%3A%22%D0%A1%D1%83%D1%80%D0%B8%D0%BA%D0%BE%D0%B2%22%2C%22username%22%3A%22SurikovAleksey%22%2C%22language_code%22%3A%22ru%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1727442821&hash=72f45317c2d0066a71af9ad211572b26baefe1c2009102389b8583a92411fc67`,
              initData: tg?.initData,
              refId: tgParams
            })
            .then((res) => {
              setUser(res)
              console.log(res)
            })
            .catch((err) => {
              console.log(err);
              setSucces(false)
            })
        }
        else {
          setUser(res)
        }
      })
      .catch((err) => {
        console.log(err);
        mainApi
          .authLogin({
            // initData: `query_id=AAEyeS0XAAAAADJ5LRff1Qpb&user=%7B%22id%22%3A388856114%2C%22first_name%22%3A%22%D0%9B%D1%91%D1%88%D0%B0%22%2C%22last_name%22%3A%22%D0%A1%D1%83%D1%80%D0%B8%D0%BA%D0%BE%D0%B2%22%2C%22username%22%3A%22SurikovAleksey%22%2C%22language_code%22%3A%22ru%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1727442821&hash=72f45317c2d0066a71af9ad211572b26baefe1c2009102389b8583a92411fc67`,
            initData: tg?.initData,
            refId: tgParams
          })
          .then((res) => {
            setUser(res)
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
            setSucces(false)
          })
      })
  }, [])

  useEffect(() => {
    if (!user) return
    setTotal((prevState) => ({
      ...prevState,
      initial: Number(user.balance_data?.balance),
      persec: Number(user?.balance_data?.income_an_hour)
    }))
    setEnergyCounter(Number(user?.balance_data?.energy))

    setIncome(user.income_since_last_request)
    setLearn(user?.register_stage)
    setRaitingNumber(user?.balance_data?.rating)

    if (user?.user_data?.first_name ||
      user?.user_data?.last_name ||
      user?.user_data?.date_of_birth ||
      user?.user_data?.email) {
      setPersonalData(user?.user_data)
    }

    if (user?.user_data?.phone === null) {
      setPhoneOpen(true)
    }

    if (user?.user_data?.username === null) {
      setUser((prevValue) => ({
        ...prevValue,
        user_data: {
          ...prevValue.user_data,
          username: `${niknames[randomIndex]}#${randomNumber}`
        }
      }))
    }
    setPreloaders((prevState) => ({
      ...prevState,
      rewards: true
    }))
    mainApi.getAwards({ limit: AWARDS_LIMIT })
      .then((res) => {
        console.log(res.data)
        setRewards(res.data)
        // setRewards(TASK)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setPreloaders((prevState) => ({
          ...prevState,
          rewards: false
        }))
      })

  }, [user])

  useEffect(() => {
    if (isOpenRating || preloaders.app || isOpenRewards || learn === 0 || phoneOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "initial"
    }
    // console.log(isOpenRating, preloaders.app, isOpenRewards, learn === 0, phoneOpen)
  }, [location, isOpenRating, isOpenRewards, learn, preloaders.app, phoneOpen]);

  useEffect(() => {
    if (!user) return
    if (user?.register_stage === 0) {
      setGiftOpen(true)
      setLearnPopup(true)
    }
  }, [user?.register_stage]);

  useEffect(() => {
    if (!user) return
    setTimeout(() => {
      mainApi
        .getMe()
        .then((res) => {
          if (res.received_profile_bonus !== user?.received_profile_bonus && Number(res.balance_data.balance) !== Number(user?.balance_data.balance)) {
            setUser((prevValue) => ({
              ...prevValue,
              user_data: {
                ...personalData,
                username: prevValue.user_data.username
              },
              balance_data: {
                ...res.balance_data,
                balance: Number(res.balance_data.balance),
                energy: Number(res.balance_data.energy),
                energy_an_hour: Number(res.balance_data.energy_an_hour),
                income_an_hour: Number(res.balance_data.income_an_hour),

              },
              received_profile_bonus: res.received_profile_bonus
            }))
          }
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        })
    }, 2000)
  }, [personalData])

  useEffect(() => {
    if (!user) return
    const delayDebounceFn = setTimeout(() => {
      mainApi
        .getMe()
        .then((res) => {
          if (Number(res.balance_data?.level) !== Number(user.balance_data?.level)) {
            setUser((prevValue) => ({
              ...prevValue,
              // user.balance.level
              balance_data: {
                ...prevValue.balance_data,
                level: res.balance_data.level
              }
            }))
            setOpenLevelUp(!isOpenLevelUp)
          }
          if (res.balance_data?.rating !== user?.balance_data?.rating) {
            // setUser(res)
            setRaitingNumber(res.balance_data?.rating)
          }
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        })
    }, 3000000)

    return () => {
      clearTimeout(delayDebounceFn);
    }
  }, [user])

  const backButton = tg?.BackButton;

  useEffect(() => {
    if (location.pathname !== '/') {
      backButton?.show();
    } else {
      backButton?.hide();
    }
    const handleBackClick = () => {
      navigate(-1);
    };
    backButton?.onClick(handleBackClick);
    return () => {
      backButton?.offClick(handleBackClick);
    };
  }, [location.pathname]);

  return (
    tg && tg.platform && (tg.platform === 'ios' || tg?.platform === 'android') ?
      <div className={`app ${location.pathname === '/' ? 'app_scrollable' : ''}`}>
        <ThemeContext.Provider value={{ setTheme, theme }}>
          {preloaders.app || !user ? <Loading success={success} /> : null}
          {(location.pathname === '/friends' || location.pathname === '/profile') ?
            null :
            <Header
              rait={raitingNumber}
              setRaitingNumber={setRaitingNumber}
              isOpen={isOpenRating}
              setOpen={setOpenRating}
              user={user}
              total={total}
            />}
          <IncomePopup
            personalBonus={personalBonus}
            setPersonalBonus={setPersonalBonus}
            income={income} setIncome={setIncome} />
          <LearnPopup
            setUser={setUser}
            learnPopup={learnPopup}
            setLearnPopup={setLearnPopup}
            learn={learn} setLearn={setLearn} />
          <PhonePopup
            isOpen={phoneOpen}
            setOpen={setPhoneOpen}
            text={`Для создания виртуальной карьеры поделитесь номером телефона`} />
          <GiftPopup isOpen={isGiftOpen} setOpen={setGiftOpen} referral={user?.referral_info} />

          <Routes>
            <Route index element={
              <Main
                user={user}
                isOpenRewards={isOpenRewards} setOpenRewards={setOpenRewards}
                total={total} setTotal={setTotal}
                tabOpen={tabOpen} setTabOpen={setTabOpen}
                personalData={personalData}
                setEnergyCounter={setEnergyCounter} energyCounter={energyCounter}
                // clickable={clickable} setClickable={setClickable}
                isOpen={isOpenRating} setOpen={setOpenRating}
                preloader={preloaders.rewards} setPreloader={setPreloaders}
                rewards={rewards} setRewards={setRewards}
              />} />

            <Route path="/business" element={
              <Business
                total={total}
                setTotal={setTotal}
                user={user}
              />
            } />
            <Route path='/friends' element={<Friends user={user} />}></Route>
            <Route path='/profile' element={
              <Profile
                setUser={setUser}
                user={user}
                setTabOpen={setTabOpen}
                tabOpen={tabOpen}
                total={total}
                personalData={personalData}
                setPersonalData={setPersonalData}
                setTotal={setTotal}
                personalBonus={personalBonus}
                setPersonalBonus={setPersonalBonus}
              />} />
          </Routes>
          <NavBlock setOpen={setOpenRating} personalData={personalData} />
          <LevelUpPopup isOpenLevelUp={isOpenLevelUp} setOpenLevelUp={setOpenLevelUp} />
          <Rating
            isOpenRating={isOpenRating}
            setOpenRating={setOpenRating}
            user={user}
            total={total}
          />
          <Rewards
            user={user}
            isOpenRewards={isOpenRewards}
            setOpenRewards={setOpenRewards}
            setTotal={setTotal}
            total={total}
            rewards={rewards} setRewards={setRewards}
          />
          <Count user={user} setTotal={setTotal} setCountVisible={setCountVisible} total={total} countVisible={countVisible} />
        </ThemeContext.Provider>
      </div>
      :
      <ForComputer />
  );
}


export default App;
