import { Coin, CoinGrey } from '../../../assets/icons/icons';
import '../FriendsCard/FriendsCard.css';
import defaultPhoto from '../../../assets/images/defaultPhoto.webp';
import { BONUS_FOR_FRIENDS } from '../../../assets/utils/constants';

function FriendsCard({ item, preloader }) {

    return (
        <div className={`${preloader ? 'friends-card_animated-gradient' : ''} friends-card`}>
            <div className='frineds-card__left'>
                <img src={item?.user_data?.avatar?.urls?.orig ? item?.user_data?.avatar?.urls?.orig : defaultPhoto} alt='' className='friends-card__img'></img>
                <div className={`friends-card__name-box`}>
                    <p className='friends-card__name'>{item.user_data?.username ? item.user_data?.username : item.user_data?.first_name}</p>
                    <p className='friends-card__bonus'>{BONUS_FOR_FRIENDS}<CoinGrey /></p>
                </div>
            </div>
            <div className='frineds-card__right'>
                <p className='friends-card__income'>
                    {Intl.NumberFormat('en-US', {
                        notation: "compact",
                        maximumFractionDigits: 2
                    }).format(Number(item.balance_data?.total_income))}
                    <Coin mainClassName={'friends-card__coin'} />
                </p>
               
            </div>
        </div>
    );
}

export default FriendsCard;