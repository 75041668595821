import './GiftPopup.css';
import { motion } from "framer-motion"
import TemplatePopup from '../TemplatePopup/TemplatePopup';
import { Coin } from '../../assets/icons/icons';
import gift from '../../assets/images/gift.webp'
import { BONUS_FOR_FRIENDS } from '../../assets/utils/constants';

function GiftPopup({ referral, isOpen, setOpen }) {
    const variants = {
        open: {
            // y: "0%",
            opacity: 1,
            transition: { duration: 0.5 },
        },
        closed: {
            // y: "100%",
            opacity: 0,
            transition: { duration: 0.5 },
            // transitionEnd: {
            //     display: "none",
            // },
        }
    };

    function handleClick() {
        window.Telegram?.WebApp.HapticFeedback.impactOccurred('soft')
        setOpen(false)
    }

    return (
        <motion.div
            // initial={false}
            // animate={isOpen ? "open" : "closed"}
            // variants={variants}
            className={`${isOpen ? 'gift-popup_visible' : 'gift-popup_unvisible'} gift-popup-box`}
        >
            {isOpen ? <img src={gift} alt='' className='gift-popup__bg' /> : null}
            {isOpen ? <div className='gift-popup__circle_roze'></div> : null}
            {isOpen ? <div className='gift-popup__circle_purple'></div> : null}
            <TemplatePopup secondClassName={'gift-popup'} isOpen={isOpen} setOpen={setOpen}>
                {referral?.parent?.user_data?.username ?
                    <p className='gift-popup__text_white'>Получите подарок от</p>
                    :
                    <p className='gift-popup__text_white'>Получите подарок</p>
                }
                <p className='gift-popup__text_orange'>
                    {referral?.parent?.user_data?.username}
                </p>
                <p className='gift-popup__coin'>
                    {BONUS_FOR_FRIENDS}
                    <Coin mainClassName={'gift-popup__icon'} />
                </p>
                <button onClick={() => handleClick()} className='gift-popup__btn'>Получить</button>
            </TemplatePopup>
        </motion.div>
    );
}

export default GiftPopup