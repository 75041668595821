import { useEffect, useState } from "react";
import { motion } from 'framer-motion'
import AuthInput from "../AuthInput/AuthInput";
import "./SignUp.css";
import InputGender from "../InputGender/InputGender";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import mainApi from "../../../../assets/api/MainApi";
import CustomInput from "../CustomInput/CustomInput";
import moment from "moment-timezone";
import validator from "validator";
import { BONUS_FOR_PERSONAL } from "../../../../assets/utils/constants";

function SignUp({ personalData, setSteps, step, handleGender, setTotal, user, personalBonus, setPersonalBonus }) {

  const initialSignupValues = {
    name: personalData.first_name ? personalData.first_name : null,
    surname: personalData.last_name ? personalData.last_name : null,
    job_title: personalData.job_title ? personalData.job_title : null,
    gender: personalData.gender !== null ? personalData.gender : null,
    email: personalData.email ? personalData.email : null,
    date: personalData.date_of_birth ? personalData.date_of_birth : null
  };

  const initialSugnupValidity = {
    name: {},
    email: personalData.email ? {
      validState: true,
      errorMessage: '',
    } : {},
    surname: {},
    job_title: {},
    gender: {},
    date: personalData.date_of_birth ? {
      validState: true,
      errorMessage: '',
    } : {},
  };

  const DATE_NOW = moment();
  const MAX_DATE = moment().subtract(18, 'years');
  const MIN_DATE = moment('1900-01-01', 'YYYY-MM-DD');

  const [signupValues, setSignupValues] = useState(initialSignupValues);
  const [signupValidity, setSignupValidity] = useState(initialSugnupValidity);

  const [preloader, setPreloader] = useState(false)
  const isDisabled = !(
    (signupValues.name ? signupValues.name?.length > 1 : true) && (signupValues.surname ? signupValues.surname?.length > 1 : true) && (signupValues.job_title ? signupValues.job_title?.length > 1 : true) && (signupValues.email ? signupValidity.email.validState : true) && (signupValues.date ? signupValidity.date.validState : true)
  );
  console.log({
    isDisabled: isDisabled,
    name: (signupValues.name ? signupValues.name?.length > 1 : true),
    surname: (signupValues.surname ? signupValues.surname?.length > 1 : true),
    email: (signupValues.email ? signupValidity.email.validState : true),
    date: (signupValues.date ? signupValidity.date.validState : true),
  })

  function handleGender(value) {
    if (value === true) {
      setSignupValues((prevVal) => ({ ...prevVal, gender: true }));
    }
    else if (value === false) {
      setSignupValues((prevVal) => ({ ...prevVal, gender: false }));
    }
  }

  function handleSignupValuesChange(evt) {
    const input = evt.target;
    const name = input.name;
    const value = input.value;

    switch (name) {
      case "email": {
        setSignupValues((prevVal) => ({ ...prevVal, [name]: value }));
        if (!value) {
          setSignupValidity((prevValue) => ({
            ...prevValue,
            [name]: {
              errorMessage: "",
              validState: false,
            },
          }));
        }
        if (value.length >= 2) {
          if (validator.isEmail(value)) {
            setSignupValidity((prevValue) => ({
              ...prevValue,
              [name]: {
                errorMessage: "",
                validState: true,
              },
            }));
          } else {
            setSignupValidity((prevValue) => ({
              ...prevValue,
              [name]: {
                errorMessage: !evt.target.validity.valid
                  ? evt.target.validationMessage
                  : "Invalid email",
                validState: false,
              },
            }));
          }
        }
        break;
      }

      case 'date':
        let dateValue = value.replace(/\D/g, '');
        let formattedDateValue = '';

        if (!dateValue) {
          setSignupValues((prevValue) => ({
            ...prevValue,
            [name]: '',
          }));
          setSignupValidity((prevValue) => ({
            ...prevValue,
            [name]: {
              // errorMessage: REQUIRED_ERR,
              validState: false,
            },
          }));
        } else {
          setSignupValidity((prevValue) => ({
            ...prevValue,
            [name]: {
              errorMessage: '',
              validState: false,
            },
          }));

          formattedDateValue += dateValue.substring(0, 2);
          if (dateValue.length >= 3) {
            formattedDateValue += '.' + dateValue.substring(2, 4);
          }
          if (dateValue.length >= 5) {
            formattedDateValue += '.' + dateValue.substring(4, 8);
          }
          if (dateValue.length >= 8) {
            const selected_date = moment(formattedDateValue);


            setSignupValidity((prevValue) => ({
              ...prevValue,
              [name]: selected_date.isValid()
                ? selected_date.diff(DATE_NOW, 'days') >= 0
                  ? {
                    // errorMessage: INVALID_DATE_VALUE_ERR,
                    validState: false,
                  }
                  :
                  selected_date.diff(MIN_DATE, 'days') > 0
                    ? {
                      errorMessage: '',
                      validState: true,
                    } : {
                      // errorMessage: INVALID_DATE_MIN_ERR(
                      //     MIN_DATE.format('DD.MM.YYYY')
                      // ),
                      validState: false,
                    }
                : {
                  // errorMessage: INVALID_DATE_VALUE_ERR,
                  validState: false,
                },
            }));
          } else {
            setSignupValidity((prevValue) => ({
              ...prevValue,
              [name]: {
                errorMessage: '',
                validState: false,
              },
            }));
          }

          setSignupValues((prevValue) => ({
            ...prevValue,
            [name]: formattedDateValue,
          }));
        }
        break;

      case 'mobile-date':
        const name_key = input.name_key;
        const selected_date = moment(value);

        setSignupValues((prevValue) => ({
          ...prevValue,
          [name_key]: value,
        }));
        setSignupValidity((prevValue) => ({
          ...prevValue,
          [name_key]: selected_date.isValid()
            ? selected_date.diff(DATE_NOW, 'days') >= 0 ?
              {
                // errorMessage: INVALID_DATE_VALUE_ERR,
                validState: false,
              }
              :
              selected_date.diff(MIN_DATE, 'days') > 0
                ? {
                  errorMessage: '',
                  validState: true,
                } : {
                  // errorMessage: INVALID_DATE_MIN_ERR(
                  //     MIN_DATE.format('DD.MM.YYYY')
                  // ),
                  validState: false,
                }
            : {
              // errorMessage: INVALID_DATE_VALUE_ERR,
              validState: false,
            },
        }));
        break;


      case "password": {
        setSignupValues((prevVal) => ({ ...prevVal, [name]: value }));
        setSignupValidity((prevVal) => ({
          ...prevVal,
          [name]: {
            errorMessage: "",
            validState: value.length >= 8,
          },
        }));
        break;
      }


      case "phone": {
        let inputValue = value.replace(/\D/g, "");
        setSignupValues((prevValue) => ({
          ...prevValue,
          [name]: inputValue,
        }));

        // if (inputValue.match(/^(?:50|51|52|55|56|58|2|3|4|6|7|9)\d{7}$/g)) {4
        if (inputValue.length >= 8) {
          setSignupValidity((prevValue) => ({
            ...prevValue,
            [name]: {
              errorMessage: "",
              validState: true,
            },
          }));
        } else {
          setSignupValidity((prevValue) => ({
            ...prevValue,
            [name]: {
              errorMessage: "",
              validState: false,
            },
          }));
        }
        break;
      }
      default: {
        setSignupValues((prevVal) => ({ ...prevVal, [name]: value }));
        setSignupValidity((prevVal) => ({
          ...prevVal,
          [name]: {
            errorMessage: "",
            validState: Boolean(value),
          },
        }));
      }
    }
  }

  const handleSubmit = async (evt) => {
    const form = evt.target;
    evt.preventDefault();
    console.log(signupValues)
  };

  function handleSave() {

    setPreloader(true)
    setSteps(1)
    setTimeout(() => {
      mainApi
        .editProfile({
          first_name: signupValues.name ? signupValues.name : null,
          last_name: signupValues.surname ? signupValues.surname : null,
          date_of_birth: signupValues.date ? signupValues.date : null,
          job_title: signupValues.job_title ? signupValues.job_title : null,
          gender: signupValues.gender,
          email: signupValues.email ? signupValues.email : null,
        })
        .then((res) => {
          console.log(res);
          // setPreloader(false)
          if (signupValues.name?.length > 0 &&
            signupValues.surname?.length > 0 &&
            signupValues.job_title?.length > 0 &&
            signupValues.date?.length > 0 &&
            signupValues.email?.length > 0 && signupValidity.email.validState &&
            signupValues?.gender !== null &&
            !user?.received_profile_bonus) {
            setPersonalBonus(true)
            setTotal((prevState) => ({
              ...prevState,
              initial: Number(prevState.initial) + BONUS_FOR_PERSONAL
            }))
          }
          setSteps(2)

        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setPreloader(false)
        })
    }, 2000)
  }

  useEffect(() => {
    console.log(user.received_profile_bonus)
  }, [user.received_profile_bonus])

  const iconCalendar = ({ mainClassName, strokeClassName }) => {
    return (
      <svg className={mainClassName} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M6.25 2.5V5" stroke="var(--contrast-color)" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.75 2.5V5" stroke="var(--contrast-color)" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.5 8.33301H2.5" stroke="var(--contrast-color)" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15 3.75H5C3.61929 3.75 2.5 4.86929 2.5 6.25V15C2.5 16.3807 3.61929 17.5 5 17.5H15C16.3807 17.5 17.5 16.3807 17.5 15V6.25C17.5 4.86929 16.3807 3.75 15 3.75Z" stroke="var(--contrast-color)" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    )
  }

  return (
    <section className="signup">
      <div className="signup__container">
        <form
          className="signup__form"
          onSubmit={handleSubmit}
        >
          <AuthInput
            name="name"
            placeholder="Имя"
            value={signupValues.name}
            error={signupValidity.name}
            icon={''}
            onChange={handleSignupValuesChange}
          />

          <AuthInput
            name="surname"
            placeholder="Фамилия"
            value={signupValues.surname}
            error={signupValidity.surname}
            icon={''}
            onChange={handleSignupValuesChange}
          />

          <AuthInput
            name="job_title"
            placeholder="Род Деятельности"
            value={signupValues.job_title}
            error={signupValidity.job_title}
            icon={''}
            onChange={handleSignupValuesChange}
          />

          <InputGender
            handleGender={handleGender}
            initialValue={initialSignupValues}
            step={step}
          />

          <CustomInput
            label="День рождения"
            value={signupValues.date}
            name="date"
            type="date"
            max={moment(DATE_NOW).startOf('day').format('YYYY-MM-DD')}
            icon={iconCalendar}
            error={signupValidity.date.errorMessage}
            onChange={(e) => {
              handleSignupValuesChange({
                target: {
                  value: e.target.value,
                  name: 'mobile-date',
                  name_key: 'date',
                },
              });
            }}
          />

          <AuthInput
            name="email"
            placeholder="Эл. почта"
            type="email"
            inputMode="email"
            value={signupValues.email}
            error={signupValidity.email}
            icon={''}
            onChange={handleSignupValuesChange}
          />

          <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
            onClick={() => handleSave()}
            className={`profile__info__btn_save ${preloader ? 'animated-gradient' : ''} ${isDisabled ? "signup__submit-btn_disabled" : ""
              }`}
            type="submit"
          >
            Сохранить
          </motion.button>
        </form>
      </div>
    </section>
  );
}

export default SignUp;
