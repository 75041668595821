import { Cue, Pause } from "../../../assets/icons/icons";
import btnCircle from '../../../assets/images/button-circle.webp';
import btnBorder from '../../../assets/images/button-bord.webp';
import '../Clicker/Clicker.css';

function Clicker({ type, colorActive, setBlockScroll, onTouchStartCapture, handleTap }) {


    return (
        <div
            onTouchMoveCapture={() => setBlockScroll(true)}
            onTouchStartCapture={() => {
                setBlockScroll(true)
                onTouchStartCapture(type)
            }}
            onTouchEndCapture={() => setBlockScroll(false)}
            onTouchStart={handleTap}
            className='main__tap-btn__box'>
            <div className={`main__tap-btn ${colorActive ? `main__tap-btn_active-${type}` : ''}`}>
                {type === 'green' ?
                    <Pause mainClassName={`main__img_btn-cue`} />
                    :
                    type === 'orange' ?
                        <Cue mainClassName={`main__img_btn-cue`} />
                        : null
                }
                <img alt='' src={btnCircle} className='main__img_btn-circle'></img>
                <img alt='' src={btnBorder} className='main__img_btn-border'></img>
                <div className={`main__img_btn-bg-lighting ${colorActive ? `main__img_btn-bg-lighting_active-${type}` : ''}`}></div>
                <div className={`main__img_btn-bg-color ${colorActive ? `main__img_btn-bg-${type}_active` : ''}`}></div>
            </div>
            <div className={`main__img_btn-bg main__img_btn-bg_active ${true ? 'main__img_btn-bg_active' : ''}`}></div>
        </div>
    )
}
export default Clicker