import '../Rewards/Rewards.css';
import { motion } from 'framer-motion'
import { AWARDS_LIMIT, TASK } from '../../assets/utils/constants';
import { useEffect, useState } from 'react';
import TemplatePopup from '../TemplatePopup/TemplatePopup';
import MainTask from '../Main/MainTask/MainTask';
import PopupTask from '../Main/PopupTask/PopupTask';
import mainApi from '../../assets/api/MainApi';

function Rewards({ isOpenRewards, setOpenRewards, setTotal, total, user, rewards, setRewards }) {
    const [currentId, setCurrentId] = useState(1)
    const [isOpen, setOpen] = useState(false)

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            mainApi
                .getAwards({ limit: AWARDS_LIMIT })
                .then((res) => {
                    if (JSON.stringify(rewards) !== JSON.stringify(res.data)) {
                        setRewards(res.data);
                    }
                    console.log(res.data);
                })
                .catch((err) => {
                    console.log(err);
                })
        }, 15000)
        return () => {
            clearTimeout(delayDebounceFn);
        }
    }, [rewards])

    function getMoreAwards() {
        const last_id = rewards[rewards.length - 1]?.id
        mainApi
            .getAwards({
                limit: AWARDS_LIMIT,
                last_id: last_id,
            })
            .then((res) => {
                console.log(res)
                if (res.length > 0) {
                    setRewards((prevValue) => (prevValue.concat(res)));
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleScroll = (event) => {
        const { scrollHeight, scrollTop, clientHeight } = event.target;
        const scroll = scrollHeight - scrollTop - clientHeight
        if (scroll == 0) {
            getMoreAwards()
        }
    }

    return (
        <TemplatePopup secondClassName={'rewards-box'} isOpen={isOpenRewards} setOpen={setOpenRewards}>
            <motion.div
                // initial={{ opacity: 0 }}
                // animate={{ opacity: 1 }}
                // transition={{ duration: 0.5 }}
                onScroll={handleScroll}
                className="rewards">
                <p className='rewards__title'>
                    Доступные награды
                </p>

                <div className='rewards__cards-grid'>
                    {rewards?.map((item, i) => {
                        return (
                            <MainTask
                                key={i}
                                item={item}
                                setCurrentId={setCurrentId}
                                currentId={currentId}
                                setOpen={setOpen}
                                isOpen={isOpen}
                            />
                        )
                    })}
                </div>
            </motion.div>
            <PopupTask
                setTotal={setTotal}
                total={total}
                isOpen={isOpen}
                setOpen={setOpen}
                data={rewards}
                currentId={currentId}
                setRewards={setRewards}
                rewards={rewards}
            />
            <div className='rewards__blur'></div>
        </TemplatePopup>
    );
}

export default Rewards;