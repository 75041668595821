import { useEffect, useState } from 'react';
import { Coin } from '../../../assets/icons/icons';
import defaultPhoto from '../../../assets/images/defaultPhoto.webp';
import './RaitingCard.css';
import { motion } from 'framer-motion'

function RaitingCard({ item, i }) {

    return (
        <motion.div
            className='rating-card'>
            <p className={`rating-card__number ${i <= 2 ? 'rating-card__name_yellow' : ''}`}>
                {i > 8 ? i + 1 : `0${i + 1}`}</p>
            <img src={item?.user_data?.avatar?.urls?.orig? item?.user_data?.avatar?.urls?.orig : defaultPhoto} alt='' className='rating-card__img'></img>
            <p className='rating-card__name'>{item?.user_data?.username}</p>
            <p className='rating-card__coin'>
                {Intl.NumberFormat('en-US', {
                    notation: "compact",
                    maximumFractionDigits: 2
                }).format(Number(item?.balance_data?.total_income))}
                <Coin mainClassName={'rating-card__icon'} />
            </p>
        </motion.div>
    );
}

export default RaitingCard;