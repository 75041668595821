import './LearnPopup.css';
import { motion } from "framer-motion"
import learnBg from '../../assets/images/learnBg.webp'
import TemplatePopup from '../TemplatePopup/TemplatePopup';
import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import learn1 from '../../assets/images/learn-1_black.webp'
import learn2 from '../../assets/images/learn-2_black.webp'
import learn3 from '../../assets/images/learn-3_black.webp'
import learn4 from '../../assets/images/learn-4_black.webp'

import learn1w from '../../assets/images/learn-1_white.webp'
import learn2w from '../../assets/images/learn-2_white.webp'
import learn3w from '../../assets/images/learn-3_white.webp'
import learn4w from '../../assets/images/learn-4_black.webp'

import { LEARN, THEME_LIGHT_TYPE } from '../../assets/utils/constants';
import mainApi from '../../assets/api/MainApi';

function LearnPopup({ learnPopup, setLearnPopup, setLearn, learn, setUser }) {

    const [isEnd, setEnd] = useState(false)
    const [swiper, setSwiper] = useState(false)
    const [active, setActive] = useState(0)
    const theme = window.Telegram.WebApp.colorScheme;

    function handleSwiperNext() {
        if (swiper) {
            window.Telegram?.WebApp.HapticFeedback.impactOccurred('soft')
            swiper?.slideNext()
            if (isEnd) {
                mainApi
                    .setRegistered()
                    .then((res) => {
                        console.log(res);
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => {
                        // setPreloaders(false)
                    })
                setLearnPopup(false)
                setUser((prevValue) => ({
                    ...prevValue,
                    register_stage: 1
                }))

                setLearn(1)
                window.Telegram?.WebApp.HapticFeedback.impactOccurred('soft')
            }
        }
    }

    const variants = {
        open: {
            // y: "0%",
            opacity: 1,
            transition: { duration: 0.5 },
        },
        closed: {
            // y: "100%",
            opacity: 0,
            transition: { duration: 0.5 },
            // transitionEnd: {
            //     display: "none",
            // },
        }
    };
    const variantsIcon = {
        open: {
            y: "0%",
            scale: 1,
            opacity: 1,
            transition: { duration: 0.5 },
        },
        closed: {
            scale: 0.8,
            y: "-100%",
            opacity: 0,
            transition: { duration: 0.5 },
        }
    };

    return (
        <motion.div
            initial={false}
            animate={learnPopup ? "open" : "closed"}
            variants={variants}
            className={`${learnPopup ? 'learn-popup_visible' : 'learn-popup_unvisible'} learn-popup`}
        // className={`learn-popup_visible learn-popup`}
        >
            {/* style={{ zIndex: theme === THEME_LIGHT_TYPE ? 0 : 1 }} */}
            <img src={learnBg} alt='' className='learn-popup__img'></img>
            <motion.img
                animate={active === 0 ? "open" : "closed"}
                variants={variantsIcon}
                alt='' src={theme === THEME_LIGHT_TYPE ? learn1w : learn1}
                className='learn-popup__img_center learn-popup__img_center-first'
            />
            <motion.img
                animate={active === 1 ? "open" : "closed"}
                variants={variantsIcon}
                alt='' src={theme === THEME_LIGHT_TYPE ? learn2w : learn2}
                className='learn-popup__img_center learn-popup__img_center-second'
            />
            <motion.img
                animate={active === 2 ? "open" : "closed"}
                variants={variantsIcon}
                alt='' src={theme === THEME_LIGHT_TYPE ? learn3w : learn3}
                className='learn-popup__img_center learn-popup__img_center-third'
            />
            <motion.img
                animate={active === 3 ? "open" : "closed"}
                variants={variantsIcon}
                alt='' src={theme === THEME_LIGHT_TYPE ? learn4w : learn4}
                className='learn-popup__img_center learn-popup__img_center-four'
            />

            <TemplatePopup secondClassName={'learn-popup__templ'} isOpen={learnPopup} setOpen={setLearnPopup}>
                <Swiper
                    pagination={{ clickable: true }}
                    modules={[Pagination]}
                    className='learn-popup__swiper'
                    onInit={(evt) => setSwiper(evt)}
                    onReachEnd={() => setEnd(true)}
                    onSlideChange={() => setActive(swiper.activeIndex)}
                >
                    {LEARN.map((item, i) => {
                        return (
                            <SwiperSlide key={i}>
                                {item.icon}
                                <div className='learn-popup__text'>
                                    {item.title}
                                    <p className='learn-popup__subtext'>
                                        {item.text}
                                    </p>
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
                <button
                    onClick={() => handleSwiperNext()}
                    className={`learn-popup__swiper__btn ${isEnd ? 'learn-popup__swiper__btn_end' : ''}`}>
                    {isEnd ? 'Старт' : 'Далее'}
                </button>
            </TemplatePopup>
        </motion.div>
    );
}

export default LearnPopup